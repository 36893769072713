import { graphql } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";

import AspectRatioWrapper from "../components/aspectRatioWrapper";
import Layout from "../components/layout";
import Link from "../components/link";
import SEO from "../components/seo";

const Img = styled.img`
  object-fit: cover;
  object-position: center;
`;

const ListingItem = ({ post, ...restProps }): ReactElement => (
  <Box width={[1, 1 / 2, 1 / 3, 1 / 4]} px={2} mb={3} {...restProps}>
    <Link to={post.pagePath}>
      <Flex flexDirection="column" alignItems="center">
        <AspectRatioWrapper ratio="1">
          <Img src={post.frontmatter.thumbnail || ""} />
        </AspectRatioWrapper>
        <span style={{ textAlign: "center" }}>{post.frontmatter.title}</span>
      </Flex>
    </Link>
  </Box>
);

ListingItem.propTypes = {
  post: PropTypes.object,
};

const ListingTemplate = ({ data, location, pageContext }): ReactElement => {
  const idPathsMap = Object.assign(
    {},
    ...get(data.allSitePage, "edges", []).map(({ node }) => ({
      [node.context.id]: node.path,
    }))
  );
  const posts = get(data.allMarkdownRemark, "edges", [])
    .map(edge => edge.node)
    .filter(node =>
      [null, pageContext.lang].includes(get(node, "frontmatter.lang"))
    );
  const title = pageContext.title;

  return (
    <Layout location={location}>
      <SEO title={title} description="" />
      <h1>{title}</h1>
      <Flex
        flexWrap="wrap"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        mx={-2}
      >
        {posts.map(post => {
          post.pagePath = idPathsMap[post.id];
          return <ListingItem key={`post-${post.id}`} post={post} />;
        })}
      </Flex>
    </Layout>
  );
};

ListingTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query($category: String!, $lang: String!) {
    categoriesYaml(value: { eq: $category }) {
      label_en
      label_zh_hant
      label_zh_hans
      value
    }
    allSitePage(
      filter: {
        pluginCreator: { name: { eq: "default-site-plugin" } }
        context: { type: { eq: "post" }, lang: { eq: $lang } }
      }
    ) {
      edges {
        node {
          path
          context {
            id
            lang
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { category: { in: [$category] } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            thumbnail
            title
            lang
          }
          fileAbsolutePath
        }
      }
    }
  }
`;

export default ListingTemplate;
